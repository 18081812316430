// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/login","layout":false,"id":"1"},"2":{"path":"/login-next","layout":false,"id":"2"},"3":{"path":"/chat/share","layout":false,"id":"3"},"4":{"path":"","layout":false,"parentId":"5","id":"4","originPath":"/"},"5":{"path":"/","isWrapper":true,"layout":false,"id":"5"},"6":{"path":"/","redirect":"/chat","parentId":"4","id":"6"},"7":{"path":"/chat","parentId":"4","id":"7"},"8":{"path":"/document/:id","layout":false,"id":"8"},"9":{"path":"force","layout":false,"id":"9"},"10":{"path":"/*","layout":false,"id":"10"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "p__login__index" */'@/pages/login/index.tsx')),
'2': React.lazy(() => import(/* webpackChunkName: "p__login-next__index" */'@/pages/login-next/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__chat__share__index" */'@/pages/chat/share/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'@/layouts/index.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "wrappers__auth" */'@/wrappers/auth.tsx')),
'6': React.lazy(() => import('./EmptyRoute')),
'7': React.lazy(() => import(/* webpackChunkName: "p__chat__index" */'@/pages/chat/index.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__document-viewer__index" */'@/pages/document-viewer/index.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__force-graph__index" */'@/pages/force-graph/index.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.jsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/root/qbank_ai_chatbox/ragflow/web/src/layouts/index.tsx')),
},
  };
}
